import React from "react";
import Helmet from "react-helmet";

const SEO_DATA = {
  description: "Instantly turn your wall into a photo album",
  title: "Flexyfoto",
  url: "https://flexyfoto.com",
  author: "flexyfoto",
  keywords: ["flexyfoto", "wall", "photo", "album"],
  img: "https://flexyfoto.com/flexy-share.jpg",
  twitterId: "@magentadigital"
};

const SEO = () => {
  return (
    <Helmet>
      <title>{SEO_DATA.title}</title>
      <meta name="google-site-verification" content="9YDR2EgeQDJVrgHKjfRXLuc2CWAnmtsFyP7hJActs5o" />
      <meta name="description" content={SEO_DATA.description} />
      <meta name="title" content={SEO_DATA.title} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="author" content={SEO_DATA.author} />

      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:image:secure_url" content={SEO_DATA.img} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={SEO_DATA.twitterId} />
      <meta name="twitter:url" content={SEO_DATA.url} />
      <meta name="twitter:title" content={SEO_DATA.title} />
      <meta name="twitter:description" content={SEO_DATA.description} />
      <meta name="twitter:image" content={SEO_DATA.img} />

      <html lang="en-GB" />
    </Helmet>
  );
};

export default SEO;
