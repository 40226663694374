import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const NavBarLogo = ({ isSticky, className }) => {
  const {
    flexyLogoImageMobile,
    flexyLogoImageDesktop,
    flexyLogoImageSticky,
  } = useStaticQuery(graphql`
    query {
      flexyLogoImageMobile: file(relativePath: { eq: "flexyfoto-logo.png" }) {
        childImageSharp {
          fixed(width: 210) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      flexyLogoImageDesktop: file(relativePath: { eq: "flexyfoto-logo.png" }) {
        childImageSharp {
          fixed(width: 242) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      flexyLogoImageSticky: file(relativePath: { eq: "flexyfoto-logo.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  const sources = [
    {
      ...flexyLogoImageMobile.childImageSharp.fixed,
      media: `(max-width: 991px)`,
      key: "mobile",
    },
    {
      ...flexyLogoImageDesktop.childImageSharp.fixed,
      media: `(min-width: 992px)`,
      key: "desktop",
    },
  ];

  return (
    <Img
      fixed={isSticky ? flexyLogoImageSticky.childImageSharp.fixed : sources}
      alt="Flexyfoto Logo"
      className={className}
    />
  );
};

export default NavBarLogo;
