import React from 'react';
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => (
  <footer id="footer">
    <div className="row d-flex align-items-center no-gutters">
      <div className="col-lg-6 order-lg-2">
        <nav className="footer-links text-lg-right text-center social-links mb-4 mb-lg-0">
          <a href="https://twitter.com/flexyfoto" target="_blank" rel="noreferrer" className="twitter">
            <FontAwesomeIcon icon={['fab', 'twitter']} />
          </a>
          {/* <a href="https://twitter.com/flexyfoto" target="_blank" rel="noreferrer" className="facebook">
            <FontAwesomeIcon icon={['fab', 'facebook']} />
          </a> */}
          <a href="https://www.instagram.com/flexyfoto/" target="_blank" rel="noreferrer" className="instagram">
            <FontAwesomeIcon icon={['fab', 'instagram']} />
          </a>
          {/* <a href="https://twitter.com/flexyfoto" target="_blank" rel="noreferrer" className="google-youtube mr-lg-1 mr-0">
            <FontAwesomeIcon icon={['fab', 'youtube']} />
          </a> */}
        </nav>
      </div>

      <div className="col-lg-6 order-lg-1">
        <nav className="footer-links text-lg-left text-center ">
          <ul className="list-group list-group-horizontal-lg border-0">
            <li className="list-group-item border-0 py-2 order-lg-2 pl-lg-3">
              <a href="mailto:dave@magentadigital.co.uk">Contact</a>
            </li>
            <li className="list-group-item border-0 py-2 order-lg-3 pl-lg-3">
              <Link to="/privacy-policy/">Privacy policy</Link>
            </li>
            <li className="list-group-item border-0 pt-2 order-lg-1 pl-lg-0 pb-0 pb-lg-2">
              <Link to="/">&copy; 2020 FlexyFoto</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
);

export default Footer;